<div *ngIf="!formulario" class="card">
  <ngx-spinner [fullScreen]="false" color="#ffff" size="medium" type="ball-clip-rotate-multiple"></ngx-spinner>
</div>
<div class="container">
  <div class="row">
    <button (click)="print()" [matTooltipPosition]="'right'" class="btn-success" mat-raised-button
      matTooltip="Baixar como PDF">
      <mat-icon>file_download</mat-icon>
    </button>
  </div>
  <div #comprovante *ngIf="formulario" id="comprovante">
    <div class="row d-flex justify-content-center">
      <mat-card>
        <mat-card-title>
          <img alt="header" height="50" src="/assets/images/header.png" width="100%">
        </mat-card-title>
        <mat-card-title class="d-flex justify-content-center">
          <img alt="Logo Tribunais" height="100" src="/assets/images/cac.png" width="100">
        </mat-card-title>
        <div class="d-flex justify-content-center">
          <div class="bottom"></div>
        </div>
        <mat-card-content class="mt-2 text-center">
          <div class="d-flex justify-content-center mt-3">
            <ul class="list-unstyled text-left">
              <li>Número : <span class="font-weight-bolder">{{formulario.numeroFormulario}}</span></li>
              <li>Período : <span>{{formulario.periodo |underscore}}</span></li>
              <li>Instituição : <span>{{formulario.cargo.instituicao.nome}}</span></li>
              <li>Cargo : <span>{{formulario.cargo.nome}}</span></li>
              <li>Ano Declaração : <span>{{formulario.anoDeclaracao}}</span></li>
            </ul>
          </div>
          <div class="d-flex justify-content-center">
            <img [src]="qrcode" alt="qr-code" height="200" width="200">
          </div>
        </mat-card-content>
        <mat-card-title>
          <img alt="footer" height="50" src="/assets/images/footer.png" width="100%">
        </mat-card-title>
      </mat-card>
    </div>
  </div>
</div>
