import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Role } from 'src/app/core/enums/role.enum';
import { Forms } from 'src/app/core/models/formulario/forms';
import { AuthenticationService, DeclaranteService, FormularioService } from 'src/app/core/services';
import { Estado } from '../../../core/enums/estado.enum';


@Component({
  selector: 'app-comprovante',
  templateUrl: './comprovante.component.html',
  styleUrls: ['./comprovante.component.css']
})
export class ComprovanteComponent implements OnInit {
  formulario!: Forms;
  @ViewChild('comprovante') comprovante!: ElementRef;
  qrcode!: any;

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private formularioService: FormularioService,
    private declaranteService: DeclaranteService,
    private spinner: NgxSpinnerService,
    private authService: AuthenticationService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.spinner.show();
    const numeroFormulario = this.route.snapshot.params.numeroFormulario;
    const declaranteId = this.authService.currentUserValue.id;
    const role = this.authService.currentUserValue.role;

    this.determineRole(declaranteId, numeroFormulario, role);
  }

  determineRole(declaranteId: number, numeroFormulario: string, role: Role): void {
    switch (role) {
      case Role.super_admin:
        this.getUsuarioFormularioDetailByNumeroFormulario(numeroFormulario);
        break;
      case Role.admin:
        this.getUsuarioFormularioDetailByNumeroFormulario(numeroFormulario);
        break;
      case Role.declarante:
        this.getDeclaranteFormularioDetailByNumeroFormulario(declaranteId, numeroFormulario);
        break;
    }
  }

  getUsuarioFormularioDetailByNumeroFormulario(numeroFormulario: string): void {
    this.formularioService.getByNumeroFormulario(numeroFormulario).subscribe({
      next: (response) => {
        this.formulario = response;
        this.qrcode = this.sanitizer.bypassSecurityTrustUrl(`data:image/jpg;base64,${this.formulario.qrcode}`);
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      }
    });
  }

  getDeclaranteFormularioDetailByNumeroFormulario(declaranteId: number, numeroFormulario: string): void {
    this.declaranteService.getFormularioByNumeroFormulario(
      declaranteId, numeroFormulario
    ).subscribe({
      next: (response) => {
        if (this.checkFormularioByEstado(response)) {
          this.formulario = response;
          this.qrcode = this.sanitizer.bypassSecurityTrustUrl(`data:image/jpg;base64,${this.formulario.qrcode}`);
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.router.navigateByUrl('declarante/formulario/draft').then();
        }
      },
      error: (error) => {
        this.spinner.hide();
      }
    });
  }

  checkFormularioByEstado(formulario: Forms): boolean {
    return formulario.estado === Estado.submetido ? true : false;
  }

  print() {
    const printContents = document.getElementById('comprovante')?.innerHTML;
    const popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin?.document.open();
    popupWin?.document.write(
      `<html>
     <head>
     <style>
     .navbar,
     .dropdown-menu,
     .footer {
       display: none !important;
     }
     @media print {
       body {
        font-family: 'Times New Roman', Times, serif;
         margin: 0;
       }

        #comprovante {
        font-family: 'Times New Roman', Times, serif;
        margin-top: 5px;
        width: 90mm;
      }

              ul {
        padding: 0 15px;
      }

      li {
        font-size: 14px;
        line-height: 1.8;
      }

      .bottom {
        height: 15px;
        width: 70%;
        border-bottom: 3px solid #602D18 !important;
      }

      .card {
        min-width: 100vw;
        min-height: 100vh;
        /* width: 100%;
        height: 100%; */
      }


        .no-print {
          display: none !important;
        }
      }
     </style>
         <!-- Include the Bootstrap CSS file and your custom print CSS file -->
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css">
     </head>
     <body onload="window.print();window.close()">
       <div class="container">${printContents}</div>
     </body>
   </html>`
    );
    popupWin?.document.close();
  }

}
